import ApiClient from "./ApiClient";

class OrganizationsApi {
  constructor(token) {
    this.token = token
  }
  // Gets all organizations the current user is allowed to view
  getOrganizations() {
    return ApiClient(this.token).get(
      `organizations?include=projects,subscriptions,partnerOrganization,credits,credits.creditUsages,productGroups,productGroups.products,transactionCustomPrices`
    )
  }
  // Gets info about a single organization
  getSingleOrganization(organization_id, includes) {
    return ApiClient(this.token).get(`organizations/${organization_id}?include=${includes}`)
  }
  // Creates an organization
  createOrganization(payload) {
    return ApiClient(this.token).post(`organizations`, payload)
  }
  // Edits info about an organization
  editOrganization(organizationId, payload) {
    return ApiClient(this.token).put(`organizations/${organizationId}`, payload)
  }
  // Deletes an organization
  deleteOrganization(organizationId) {
    return ApiClient(this.token).delete(`organizations/${organizationId}`)
  }
  // Grants permissions to a user related to an organization. Permissions that can be granted are index,edit and delete
  grantOrganizationAccess(user_id, organization_id, permissions) {
    return ApiClient(this.token).post(`grant-organization-access`, {
      user_id,
      organization_id,
      permissions
    })
  }
  grantOrganizationAccesses(user_ids, organization_id, permissions) {
    return ApiClient(this.token).post(`grant-organization-accesses`, {
      user_ids,
      organization_id,
      permissions
    })
  }
  // Revokes permissions from a user related to an organization. Permissions that can be revoked are index,edit and delete
  denyOrganizationAccess(user_id, organization_id, permissions) {
    return ApiClient(this.token).post(`deny-organization-access`, {
      user_id,
      organization_id,
      permissions
    })
  }
  // requestCredits
  requestCredits(payload) {
    return ApiClient(this.token).post(`transaction_orders`, payload)
  }

  resumeTransactionOrder(orderId) {
    return ApiClient(this.token).post(`transaction_orders/${orderId}/resume`)
  }

  cancelTransactionOrder(orderId) {
    return ApiClient(this.token).post(`transaction_orders/${orderId}/cancel`)
  }

  // Get Transaction Orders
  getTransactionOrders() {
      return ApiClient(this.token).get(`transaction_orders`)
  }
}

export default (
    (token) => new OrganizationsApi(token)
)
